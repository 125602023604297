<template>
  <nav class="w-full flex items-center flex-row-reverse dark:text-white py-8">
    <div class="flex items-start relative">
      <div
        class="w-8 h-8 rounded-full bg-gray-100 border-2 border-primary-color"
      ></div>
      <div
        v-if="user"
        class="
          user-options
          ml-3
          xl:block
          lg:block
          md:hidden
          hidden
          cursor-pointer
        "
        @click="showOption = !showOption"
      >
        <h2 class="text-sm font-semibold leading-3 capitalize">
          {{ user.first_name }} {{ user.last_name }}
        </h2>
        <div class="text-xs text-legend-gray dark:text-border-color mt-1">
          @{{ user.username }}
        </div>
      </div>
      <img
        src="@/assets/images/chevron-down.svg"
        alt="user profile"
        class="user-options ml-3 p-2 cursor-pointer"
        @click="showOption = !showOption"
      />
      <ul
        v-click-outside="hideDropdown"
        v-if="showOption"
        class="
          user-options
          absolute
          top-full
          mt-3
          text-sm
          capitalize
          bg-white
          dark:bg-dark-mode-primary dark:text-border-color
          border border-main-border
          rounded-md
          w-full
          font-semibold
          shadow
          z-50
        "
      >
        <li
          v-if="$route.name !== 'Profile'"
          class="hover:bg-gray-100 cursor-pointer"
        >
          <router-link
            :to="{
              name: 'Profile',
              params: { username: this.user.username },
            }"
            class="block p-3"
            >profile</router-link
          >
        </li>
        <li class="p-3 hover:bg-gray-100 cursor-pointer" @click="logout">
          logout
        </li>
      </ul>
    </div>
    <div class="mx-7" @click="$emit('open-notification')">
      <img src="@/assets/images/notification-icon.svg" alt="notifications" />
    </div>
    <div class="flex items-center cursor-pointer" @click="$emit('open-cart')">
      <img src="@/assets/images/cart-icon.svg" alt="carts" />
      <span
        class="
          ml-2
          capitalize
          font-semibold
          text-sm
          xl:inline-flex
          lg:inline-flex
          md:inline-flex
          hidden
        "
        >my cart</span
      >
      <span
        style="min-width: 18px; height: 18px"
        class="
          bg-primary-red
          text-white text-xs
          flex
          items-center
          justify-center
          rounded-full
          ml-1
          p-1
        "
        >{{ cart.length }}</span
      >
    </div>
    <SwithTheme @toggle-mode="changeMode" />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import SwithTheme from '@/components/UI/Switch.vue'
import errorModule from '@/util/error.handle'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'AppBar',
  components: { SwithTheme },
  watch: {
    $route: {
      handler() {
        this.showOption = false
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(['cart', 'user']),
  },
  data() {
    return {
      showOption: false,
    }
  },
  directives: {
    ClickOutside,
  },
  methods: {
    hideDropdown(event) {
      if (!Array.from(event.target.classList).includes('user-options')) {
        this.showOption = false
      }
    },
    changeMode() {
      this.$store.commit('CHANGE_MODE')
    },
    async logout() {
      try {
        this.showOption = false

        const { status } = await this.axios.get('/account/logout')

        if (status === 200) {
          this.$store.dispatch('logOut')
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
